import { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { LoaderProvider } from '@bluecodecom/loader';

import '@bluecodecom/fonts';

import { initI18n } from '@bluecodecom/common/i18n';

import './styles.css';

const App = lazy(() => import('./app'));

initI18n(
  {
    de: {
      translation: {},
    },
    en: {
      translation: {},
    },
    it: {
      translation: {},
    },
  },
  {
    interpolation: {
      escapeValue: false,
    },
  },
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <LoaderProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </LoaderProvider>,
);
